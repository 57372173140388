import headshot from "./images/profile_3.jpeg";

function App() {
  const boxes = [
    {
      header: "Full Stack Development",
      bullets: ["Ruby on Rails", "React", "Expo / React Native (mobile)"],
    },
    {
      header: "UX Focus",
      bullets: ["Research & Design", "Product Management", "Analytics"],
    },
    {
      header: "Automation",
      bullets: [
        "Marketing Technologies",
        "Continuous Integration + Delivery",
        "Business Workflow",
      ],
    },
  ];
  return (
    <div className="flex flex-col font-ropa">
      <div className="flex flex-row bg-gradient-to-r from-sea-100 via-sea-200 to-sea-300 px-12 pt-14 pb-10 text-gray-100">
        <div className="flex flex-1 flex-col">
          <h1 className="font-nowbold text-8xl sm:text-9xl">
            Bit <br />
            Haiku
          </h1>
          <p className="pr-12 font-ropa text-2xl">
            We design and implement full-stack solutions so you can focus on
            your business.
          </p>
          <div>
            <a
              href="#contact"
              className="my-5 inline-block bg-gray-100 px-5 py-2 font-nowbold text-sea-100"
            >
              WORK WITH BIT HAIKU
            </a>
            <div className="invisible sm:visible"></div>
          </div>
        </div>
        <div className="flex-1"></div>
      </div>

      <div className="bg-gray-400 py-24 px-12 text-white">
        <div className="sm:flex sm:flex-row sm:items-center">
          <div className="font-nowbold text-5xl sm:w-0.5 sm:flex-1 sm:text-6xl">
            We write code with purpose
            <span className="text-sea-100">.</span>
          </div>
          <div className="mt-10 font-ropa text-2xl sm:mt-0 sm:w-0.5 sm:flex-1">
            <p>Highly scalable so growth is not a pain.</p>
            <p>Well tested so you can sleep better.</p>
            <p>Constantly communicated so you're always in the loop.</p>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col justify-around bg-gradient-to-b from-sea-100 via-sea-200 to-sea-300 py-14 px-12 text-gray-100
      md:flex-row"
      >
        {boxes.map((box) => (
          <div
            className="lg:px-15 my-10 flex-1 bg-gray-400 px-5 py-8 md:mx-5 md:my-0 lg:px-10"
            key={box.header}
          >
            <h2 className="mb-4 text-center font-nowbold text-2xl sm:text-3xl">
              {box.header}
            </h2>
            <ul className="list-inside list-disc sm:text-xl">
              {box.bullets.map((bullet, i) => (
                <li key={i}>{bullet}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="flex flex-col items-center bg-gray-400 py-20 px-14 text-white sm:flex-row">
        <div className="flex flex-1 flex-col">
          <img
            src={headshot}
            alt="Headshot of Tamas Erdos"
            className="h-48 w-48 rounded-full"
          />

          <div className="my-3 font-ropa text-xl">HI I'M TAMAS</div>
          <div className="font-nowbold text-4xl font-bold">
            Founder of <br /> Bit Haiku
          </div>
        </div>
        <div className="mt-10 flex-1 text-xl sm:mt-0 lg:pr-72 lg:text-2xl">
          <p className="mb-5">
            I'm passionate about helping businesses grow and innovate.
          </p>
          <p className="mb-5">
            I've led innovation teams in organizations ranging from startups
            through medium sized businesses to Fortune 500 companies.
          </p>
          <p className="mb-5">
            Tell me about your pain points and let's come up with a solution
            together.
          </p>
        </div>
      </div>

      <div className="flex flex-col bg-gradient-to-b from-sea-100 via-sea-200 to-sea-300 py-36 px-20 text-center sm:text-left">
        <div className="flex flex-1 sm:flex-row flex-col">
          <div className="flex-1 bg-white py-10 sm:px-10 px-4 text-sea-300">
            <div className="mb-4 font-nowbold text-3xl font-bold">Contact</div>
            <div className="font-ropa sm:text-xl" id="contact">
              <a href="mailto:hey@bithaiku.com?subject=Let's work together!">
                HEY@BITHAIKU.COM
              </a>
            </div>
          </div>
          <div className="flex-1 bg-sea-300 py-10 px-10 text-white">
            <div className="mb-4 font-nowbold text-3xl font-bold">Follow</div>
            <div className="font-ropa text-xl">
              <a
                href="https://twitter.com/tamas"
                target="_blank"
                rel="noreferrer"
              >
                TWITTER
              </a>
            </div>
            <div className="font-ropa text-xl">
              <a
                href="https://github.com/erdostom"
                target="_blank"
                rel="noreferrer"
              >
                GITHUB
              </a>
            </div>
          </div>
        </div>
        <div className="flex-1 bg-gray-400 py-10 px-10 text-white">
          <div className="mb-4 font-nowbold text-3xl font-bold tracking-wider">
            Legal
          </div>
          <div className="font-ropa text-xl">
            <a
              href="https://www.iubenda.com/privacy-policy/44877060"
              title="Privacy Policy "
              target="_blank"
              rel="noreferrer"
            >
              PRIVACY POLICY
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
